import nuxtStorage from 'nuxt-storage';

export function useStoreReset() {
  function resetAllStores() {
    nuxtStorage?.sessionStorage?.removeItem('quote-payload')
    nuxtStorage?.sessionStorage?.removeItem('primary-insured')
    nuxtStorage?.sessionStorage?.removeItem('current-quote')
    nuxtStorage?.sessionStorage?.removeItem('application-info')
    nuxtStorage?.sessionStorage?.removeItem('contact-info')
    nuxtStorage?.sessionStorage?.removeItem('query-params')
    nuxtStorage?.sessionStorage?.removeItem('agent-info')    
  }

  return {
    resetAllStores,
  };
}
